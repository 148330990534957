import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

function Login() {
    return (
      <Container id="login-form" style={{width: '30rem'}}>

        <Card className="text-dark bg-light mb-3 mt-3" style={{width: '30rem'}}>
            <Card.Body>
                <Card.Title>Already have an account?</Card.Title>
                <Form className="mb-3 mt-3">
                    <Button variant="primary me-1 mt-1" type="submit">
                        Sign in with your Google account
                    </Button>

                    <Button variant="secondary me-1 mt-1" type="submit">
                        Sign in with your Microsoft account
                    </Button>
                </Form>
            </Card.Body>
            </Card>

        <Card className="text-dark bg-light mb-3 mt-3" style={{width: '30rem'}}>
            <Card.Body>
                <Card.Title>Need an account?</Card.Title>
                <Form className="mb-3 mt-3">
                    <Button variant="success me-1 mt-1" type="submit">
                        Register for a new account
                    </Button>
                </Form>
            </Card.Body>
        </Card>
        
      </Container>
    );
  }
  
  export default Login;