import React from 'react';
import Login from './components/Login';

// If user is not signed in, show the login page.  If user is signed in show body for now.
function App() {
  return (
    <div className="AppLayout">
      <Login></Login>
    </div>
  );
}

export default App;
